import React, { useState, useEffect, useRef } from 'react';
import { ObjectId, Decimal128 } from 'bson';
import { View, Text, ScrollView, Dimensions } from 'react-native';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { ControlTypes } from '../../Constants/ControlTypes';
import DynamicTextArea from '../DynamicControls/DynamicTextArea';
import {
  AttachmentConfig,
  GroupSelectConfig,
  LabelConfig,
  RatingConfig,
  SegmentConfig,
  TagItemConfig,
  TextBoxConfig,
  TextAreaConfig,
  LinkRecordConfig,
  ReportDescriptionConfig,
  SelectItemConfig,
  TipConfig,
  LinkedDropDownsConfig,
  RiskRatingConfig,
  DatePickerConfig,
  TimePickerConfig,
  FacePileConfig,
  AutocompleteConfig,
  AutocompleteExtendedConfig,
  RadioConfig,
  CheckboxGroupConfig,
  SignatureConfig,
  SignatureListConfig,
} from '../../Types/ControlConfigTypes';
import DynamicLabel from '../DynamicControls/DynamicLabel';
import DynamicDatePicker from '../DynamicControls/DynamicDatePicker';
import DynamicTimePicker from '../DynamicControls/DynamicTimePicker';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import DynamicTagItems from '../DynamicControls/DynamicTagItems';
import { SubmissionAnswerDTO } from '../../Types/DtoTypes';
import DynamicGroupSelector from '../DynamicControls/DynamicGroupSelector';
import DynamicRating from '../DynamicControls/DynamicRating';
import DynamicLocationMap from '../DynamicControls/DynamicLocationMap';
import DynamicAttachments from '../DynamicControls/DynamicAttachments';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import DynamicSignature from '../DynamicControls/DynamicSignature';
import DynamicSignatureList from '../DynamicControls/DynamicSignatureList';
import DynamicLinkRecord from '../DynamicControls/DynamicLinkRecord';
import DynamicReportDescription from '../DynamicControls/DynamicReportDescription';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import DynamicLinkedDropDowns from '../DynamicControls/DynamicLinkedDropDowns';
import DynamicFacepile from '../DynamicControls/DynamicFacepile';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import {
  TemplateVersion_pages,
  TemplateVersion_pages_controls,
  TemplateVersion_conditions,
} from '../../Models/RealmModels/TemplateVersion';
import DynamicSubmitButton from '../DynamicControls/DynamicSubmitButton';
import DynamicTip from '../DynamicControls/DynamicTip';
import DynamicRiskRating from '../DynamicControls/DynamicRiskRating';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import IConditionsHelperService from '../../Services/Interfaces/IConditionsHelperService';
import ConditionsHelperService from '../../Services/ConditionsHelperService';
import DynamicRadioControl from '../DynamicControls/DynamicRadioControl';
import DynamicCheckBoxGroup from '../DynamicControls/DynamicCheckBoxGroup';
import useDynamicDefault from '../../Hooks/useDynamicDefault';
import DynamicAutocompleteExtended from '../DynamicControls/DynamicAutocompleteExtended';
import { DEFAULT_SOURCE_KEY } from '../../Constants/AppConstants';

type DynamicPageProps = {
  navigation: any;
  submissionId: ObjectId;
  submissionSQLServerId: string;
  onChange?: (
    controlId: number | undefined,
    controlTypeId: number | undefined,
    value: string,
    isValid: boolean,
  ) => void;
  updateMetaData?: (key: string, value: string) => void;
  submitForm: () => void;
  validateAllPages?: () => boolean;
  answers: SubmissionAnswerDTO[];
  page: TemplateVersion_pages;
  pageNumber: number;
  conditions?: TemplateVersion_conditions[];
  controlHash?: Map<number, TemplateVersion_pages_controls>; //Conditions across many pages
  disabled: boolean;
  showSubmitButton?: boolean;
  validations: {
    controlId: number;
    Label: string;
    page: number;
    isValid: boolean;
  }[];
  showErrors?: boolean;
  externalView?: boolean;
  setIsLoading: (loading: boolean) => void;
};

const DynamicPage = (props: DynamicPageProps): React.ReactElement => {
  const conditionsHelper: IConditionsHelperService =
    new ConditionsHelperService();

  const [focusedControlId, setFocusedControlId] = useState<number | undefined>(
    undefined,
  );

  const [controlHash, setControlHash] = useState<
    Map<number, TemplateVersion_pages_controls>
  >(new Map<number, TemplateVersion_pages_controls>());

  const { getDefaultValue } = useDynamicDefault();

  useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTo({ y: 0, animated: false });

    let newControlHash: Map<number, TemplateVersion_pages_controls> = new Map<
      number,
      TemplateVersion_pages_controls
    >();

    for (let i = 0; i < props.page.controls!.length; i++) {
      newControlHash.set(
        props.page.controls![i].controlId!,
        props.page.controls![i],
      );
    }

    setControlHash(newControlHash);
  }, [props.pageNumber]);

  const onSubmit = () => {
    if (props.validateAllPages && !props.validateAllPages()) return;

    props.submitForm();
  };

  const getFormErrors = (): string => {
    let errors = '';

    let hasErrors = props.validations.some(v => !v.isValid);
    if (hasErrors) {
      errors = 'You have some missing/invalid field values:\n';

      props.validations
        .filter(v => !v.isValid)
        .forEach(v => {
          if (v.Label) errors += '\t•' + v.Label + '\n';
        });
    }

    return errors;
  };

  function setDefault(
    answer: string | undefined,
    sourceKey: string,
    control: TemplateVersion_pages_controls,
  ) {
    if (answer == undefined && sourceKey) {
      answer = getDefaultValue(sourceKey);
      if (answer && props.onChange)
        props.onChange(control.controlId, control.controlTypeId, answer, true);
    }

    return answer;
  }

  const getControl = (
    control: TemplateVersion_pages_controls,
    controlHash: Map<number, TemplateVersion_pages_controls>,
  ): React.ReactElement => {
    const submissionAnswer = props.answers.find(
      x => x.controlId == control.controlId,
    );

    let answer: string | undefined = undefined;
    if (submissionAnswer && submissionAnswer.answer)
      answer = submissionAnswer.answer;

    let visible = conditionsHelper.isControlVisible(
      control,
      props.conditions ?? [],
      props.answers,
      controlHash,
    );
    // TODO: Use "LessThan" & "GreaterThan" conditions in ConditionsHelperService
    // I guess we'll need a DynamicNumericBox with minValue & maxValue config

    if (!visible)
      return <React.Fragment key={control.controlId}></React.Fragment>;

    let disabled = conditionsHelper.isControlDisabled(
      control,
      props.conditions ?? [],
      props.answers,
      controlHash,
    );

    //Page disabled takes priority over control conditions
    if (props.disabled) disabled = true;

    switch (control.controlTypeId) {
      case ControlTypes.LABEL: {
        let config: LabelConfig = {};

        try {
          config = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for label ' + control.controlId?.toString(),
          );
        }

        return (
          <DynamicLabel
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={config}
            value={control.label}
            visible={visible}
          />
        );
      }
      case ControlTypes.DATE:
        const datePickerConfig: DatePickerConfig = JSON.parse(control.config!);

        let before = conditionsHelper.getBeforeCondition(
          control,
          props.conditions ?? [],
          props.answers,
          props.page.controls!,
        );
        if (before) datePickerConfig.maxDate = before;

        let after = conditionsHelper.getAfterCondition(
          control,
          props.conditions ?? [],
          props.answers,
          props.page.controls!,
        );
        if (after) datePickerConfig.minDate = after;

        return (
          <DynamicDatePicker
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={datePickerConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
          />
        );
      case ControlTypes.MAP: {
        return (
          <DynamicLocationMap
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={{
              required: true,
              initialRegion: {
                latitude: 54.2217134,
                longitude: -116.7579707,
                latitudeDelta: 0,
                longitudeDelta: 0,
              },
            }}
            value={answer}
            onChange={props.onChange}
            visible={visible}
            disabled={disabled}
            showError={props.showErrors}
          />
        );
      }
      case ControlTypes.PHOTOS_AND_ATTACHMENTS:
        const attachmentConfig: AttachmentConfig = JSON.parse(control.config!);
        return (
          <DynamicAttachments
            key={control.controlId}
            submissionId={props.submissionSQLServerId}
            attachmentsType={'submission'}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={attachmentConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            setIsLoading={props.setIsLoading}
            showError={props.showErrors}
          />
        );
      case ControlTypes.RATING:
        const ratingConfig: RatingConfig = JSON.parse(control.config!);
        return (
          <DynamicRating
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={ratingConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
          />
        );
      case ControlTypes.SELECT: {
        const selectItemConfig: SelectItemConfig = JSON.parse(control.config!);

        let filter = undefined;
        if (props.controlHash)
          filter = conditionsHelper.getFilterCondition(
            control,
            props.conditions ?? [],
            props.answers,
            props.controlHash!,
          );

        return (
          <DynamicSelect
            submissionId={props.submissionId}
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={selectItemConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
            filter={filter}
          />
        );
      }
      case ControlTypes.TAG_ITEM: {
        const tagItemConfig: TagItemConfig = JSON.parse(control.config!);

        if (answer == undefined && tagItemConfig.defaultSourceKey)
          answer = setDefault(answer, tagItemConfig.defaultSourceKey, control);
        return (
          <DynamicTagItems
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={tagItemConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            showError={props.showErrors}
          />
        );
      }
      case ControlTypes.TEXTAREA: {
        const textAreaConfig: TextAreaConfig = JSON.parse(control.config!);
        return (
          <DynamicTextArea
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={textAreaConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            onFocus={target => scrollTo(target)}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
          />
        );
      }
      case ControlTypes.TEXTBOX:
        const textBoxConfig: TextBoxConfig = JSON.parse(control.config!);

        if (answer == undefined && textBoxConfig.defaultSourceKey) {
          answer = setDefault(answer, textBoxConfig.defaultSourceKey, control);

          if (
            props.updateMetaData &&
            textBoxConfig.hasMetaData &&
            textBoxConfig.metaDataKey &&
            answer
          ) {
            props.updateMetaData(textBoxConfig.metaDataKey, answer);
          }
        }

        return (
          <DynamicTextBox
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            value={answer}
            config={textBoxConfig}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
          />
        );
      case ControlTypes.TIME:
        const timePickerConfig: TimePickerConfig = JSON.parse(control.config!);
        return (
          <DynamicTimePicker
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={timePickerConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
          />
        );
      case ControlTypes.GROUP_SELECT:
        const groupSelectConfig: GroupSelectConfig = JSON.parse(
          control.config!,
        );
        return (
          <DynamicGroupSelector
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={groupSelectConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            showError={props.showErrors}
          />
        );
      case ControlTypes.SUBMIT:
        if (props.showSubmitButton !== false)
          return (
            <React.Fragment key={control.controlId}>
              <DynamicSubmitButton
                controlId={control.controlId!}
                controlTypeId={control.controlTypeId}
                label={control.label}
                onSubmit={onSubmit}
                disabled={disabled}
              />
              <Text
                style={[
                  ControlsStyleSheet.error,
                  { fontSize: 12, marginTop: 16 },
                ]}>
                {getFormErrors()}
              </Text>
            </React.Fragment>
          );
        else return <React.Fragment key={control.controlId}></React.Fragment>;
      case ControlTypes.SEGMENTED_CONTROL:
        const segmentConfig: SegmentConfig = JSON.parse(control.config!);
        return (
          <DynamicSegmentedControl
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={segmentConfig}
            label={control.label}
            value={answer}
            defaultValue={control.value}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
          />
        );
      case ControlTypes.SIGNATURE:
        let signatureConfig: SignatureConfig | undefined = undefined;

        try {
          signatureConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for signature ' + control.controlId?.toString(),
          );
        }

        return (
          <DynamicSignature
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={signatureConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onFocus={() => setScrollEnabled(false)}
            onBlur={() => setScrollEnabled(true)}
            onChange={props.onChange}
            showError={props.showErrors}
          />
        );
      case ControlTypes.SIGNATURE_LIST:
        let signatureListConfig: SignatureListConfig | undefined = undefined;

        try {
          signatureListConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for signature ' + control.controlId?.toString(),
          );
        }

        return (
          <DynamicSignatureList
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={signatureListConfig}
            label={control.label}
            value={answer}
            onChange={props.onChange}
            visible={visible}
            disabled={disabled}
            showError={props.showErrors}
          />
        );
      case ControlTypes.LINK_RECORD:
        const linkRecordConfig: LinkRecordConfig = JSON.parse(control.config!);
        return (
          <DynamicLinkRecord
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            submissionId={String(props.submissionId)}
            label={control.label}
            config={linkRecordConfig}
            navigation={props.navigation}
            visible={visible}
            disabled={disabled}
          />
        );
      case ControlTypes.REPORT_DESCRIPTION:
        const reportDescriptionConfig: ReportDescriptionConfig = JSON.parse(
          control.config!,
        );
        return (
          <DynamicReportDescription
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={reportDescriptionConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            onFocus={target => scrollTo(target)}
            showError={props.showErrors}
          />
        );
      case ControlTypes.CHECK_BOX:
        return (
          <DynamicCheckBox
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
          />
        );
      case ControlTypes.FACEPILE:
        const facePileConfig: FacePileConfig = JSON.parse(control.config!);

        if (answer == undefined && facePileConfig.defaultSourceKey)
          answer = setDefault(answer, facePileConfig.defaultSourceKey, control);

        return (
          <DynamicFacepile
            key={control.controlId}
            label={control.label}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={facePileConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
          />
        );
      case ControlTypes.LINKED_DROPDOWNS:
        const linkedDropDownsConfig: LinkedDropDownsConfig = JSON.parse(
          control.config!,
        );
        return (
          <DynamicLinkedDropDowns
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            label={control.label}
            config={linkedDropDownsConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            showError={props.showErrors}
          />
        );
      case ControlTypes.TIP:
        const tipConfig: TipConfig = JSON.parse(control.config!);

        return (
          <DynamicTip
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={tipConfig}
            value={answer}
            visible={visible}
          />
        );
      case ControlTypes.AUTOCOMPLETE:
        let autocompleteConfig: AutocompleteConfig = {};

        try {
          autocompleteConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for autocomplete ' + control.controlId?.toString(),
          );
        }

        let autofill = undefined;
        if (props.controlHash)
          autofill = conditionsHelper.getAutofillCondition(
            control,
            props.conditions ?? [],
            props.answers,
            props.controlHash!,
          );

        if (answer == undefined && autocompleteConfig.defaultSourceKey) {
          let defaultValue = getDefaultValue(
            autocompleteConfig.defaultSourceKey,
            props.submissionId.toHexString(),
          );

          if (defaultValue) answer = defaultValue;
        }

        let disabledBasicTrainee = false;
        if (
          answer &&
          autocompleteConfig.defaultSourceKey === DEFAULT_SOURCE_KEY.TRAINEE
        )
          disabledBasicTrainee = true;

        return (
          <DynamicAutocomplete
            submissionId={props.submissionId}
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={autocompleteConfig}
            navigation={props.navigation}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled || disabledBasicTrainee}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
            onFocus={() => setFocusedControlId(control.controlId)}
            focusedControlId={focusedControlId}
            autofill={autofill}
          />
        );
      case ControlTypes.AUTOCOMPLETE_EXTENDED:
        let autocompleteExtendedConfig: AutocompleteExtendedConfig = {};

        try {
          autocompleteExtendedConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for autocomplete ' + control.controlId?.toString(),
          );
        }

        let autofillExtended = undefined;
        if (props.controlHash)
          autofillExtended = conditionsHelper.getAutofillCondition(
            control,
            props.conditions ?? [],
            props.answers,
            props.controlHash!,
          );

        if (
          answer == undefined &&
          autocompleteExtendedConfig.defaultSourceKey
        ) {
          answer = setDefault(
            answer,
            autocompleteExtendedConfig.defaultSourceKey,
            control,
          );

          if (
            props.updateMetaData &&
            autocompleteExtendedConfig.hasMetaData &&
            autocompleteExtendedConfig.metaDataKey &&
            answer
          ) {
            props.updateMetaData(
              autocompleteExtendedConfig.metaDataKey,
              answer,
            );
          }
        }

        return (
          <DynamicAutocompleteExtended
            submissionId={props.submissionId}
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={autocompleteExtendedConfig}
            navigation={props.navigation}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            onBlur={() => setScrollEnabled(true)}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
            autofill={autofillExtended}
          />
        );
      case ControlTypes.RISK_RATING:
        const riskRatingConfig: RiskRatingConfig = JSON.parse(control.config!);
        return (
          <DynamicRiskRating
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            label={control.label}
            config={riskRatingConfig}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            showError={props.showErrors}
            externalView={props.externalView}
          />
        );
      case ControlTypes.RADIO:
        let radioConfig: RadioConfig | undefined = undefined;

        try {
          radioConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for segment ' + control.controlId?.toString(),
          );
        }

        return (
          <DynamicRadioControl
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={radioConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
          />
        );
      case ControlTypes.CHECKBOXGROUP:
        let checkGroupConfig: CheckboxGroupConfig | undefined = undefined;
        try {
          checkGroupConfig = JSON.parse(control.config!);
        } catch (err) {
          console.error(
            'Invalid config for checkbox group ' +
              control.controlId?.toString(),
          );
        }

        return (
          <DynamicCheckBoxGroup
            key={control.controlId}
            controlId={control.controlId!}
            controlTypeId={control.controlTypeId}
            config={checkGroupConfig}
            label={control.label}
            value={answer}
            visible={visible}
            disabled={disabled}
            onChange={props.onChange}
            updateMetaData={props.updateMetaData}
            showError={props.showErrors}
          />
        );
      default:
        return <React.Fragment key={control.controlId}></React.Fragment>;
    }
  };

  const scrollRef = useRef<ScrollView | null>(null);
  const scrollTo = (position: number) => {
    if (scrollRef.current)
      scrollRef.current.scrollTo({ y: position, animated: true });
  };

  const controls: React.ReactElement[] = [];
  if (props.page && props.page.controls)
    for (let i = 0; i < props.page.controls.length; i++) {
      let control = props.page.controls![i];
      try {
        controls.push(getControl(control, controlHash));
      } catch (err) {
        console.log(control);
        console.log(err);
        console.error('bad contrl');
      }
    }

  const [scrollEnabled, setScrollEnabled] = useState(true);

  return (
    <ScrollView
      ref={ref => {
        scrollRef.current = ref;
      }}
      scrollEnabled={scrollEnabled}
      style={DynamicPageStyleSheet.body}>
      <View
        onPointerDown={() => setFocusedControlId(undefined)}
        style={DynamicPageStyleSheet.controlsContainer}>
        {controls}
      </View>
    </ScrollView>
  );
};

export default DynamicPage;
